import {
  Box,
  // Button,
  // Heading,
  // Image,
} from "../../../components/StyledComponent";
import {
  // faPause,
  // faPlay,
  faVolumeHigh,
  faVolumeMute,
  // faVolumeOff,
  faTimes,
  faWindowMinimize
} from "@fortawesome/free-solid-svg-icons";
import { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RoutePattern } from "../../../routes/RoutePattern";
import styled from "styled-components";
// import { isMobile } from "../../../constants";
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';

import SITE_BG from "../../../assets/images/BDK desktop bg.png";
import ALBUM_ART from "../../../assets/images/KTM BDK img.png";
import BG_IMG from "../../../assets/images/pop-up bg.png";

import AMAZON from "../../../assets/images/Amazon Music Icon.png";
import APPLE from "../../../assets/images/Apple Music Icon.png";
import DEEZER from "../../../assets/images/Deezer Icon.png";
import SPOTIFY from "../../../assets/images/Spotify Icon.png";
import TIDAL from "../../../assets/images/Tidal Icon.png";
import YOUTUBE from "../../../assets/images/YT Music Icon.png";
import $ from "jquery";

const Showcase = () => {
  const [currentMessage, setCurrentMessage] = useState<string>("");
  const MESSAGES = [
    "I see you looking like some money in this h**",
    "Don’t let them try to play you, you know you that b*tch",
    "You got all this motion, you can’t pause for no d***",
    "Don’t ever trip off no n****, you ain’t no pick me",
    "Dey Know You’re The Prize",
    "You a bad b*tch so they gotta look, OWN IT!",
    "You’re HER, they couldn’t find a better person",
    "Ain’t no other b*tch like you and Dey Know it",
    "You Perfect, You flawless",
    "You deserve it all, don’t settle for anything less than",
    "Remember you are the prize and if there’s anyone that don’t make you feel like you are, then F*CK EM.",
    "You better smile today because you fine, paid, and that b*tch"
  ];

  const modalOpen = () => {
    const modal = document.getElementById("modal") as any;

    if (modal) {
      setCurrentMessage(MESSAGES[getRndInteger(0, MESSAGES.length - 1)]);
      setTimeout(() => {
        modal.showModal();
      }, 1000);
    }
  };

  const modalClose = () => {
    const modal = document.getElementById("modal") as any;

    if (modal) {
      modal.close();
    }
  }

  function scrollTop() {
    window.scrollTo({ top: 0, behavior: 'auto' });
  }

  function getRndInteger(min: number, max: number) {
    return Math.floor(Math.random() * (max - min) ) + min;
  }

  const handleClick = () => {
    // window.location.href = '#';
    window.open('https://kentheman.komi.io/');
    modalOpen();
  }

  return (
    <Box
      id={RoutePattern.HOME}
      className="relative flex flex-col justify-center items-center h-screen w-full overflow-hidden py-10"
    >
      <img src={SITE_BG} className="h-full w-full object-cover object-center absolute top-0" />
      <Box className="z-30 flex flex-col justify-center items-center h-full w-full">
        <div className="flex justify-end w-5/6 md:w-3/12 3xl:w-1/4 bg-gray-300 border-2 border-b-gray-400 border-r-gray-400">
          <span className="border-2 border-b-gray-400 border-r-gray-400 leading-3 pl-[0.1rem] pr-[0.15rem] text-black leading-none my-0.5 mx-0.5 text-xs"><FontAwesomeIcon icon={faWindowMinimize as any} /></span>
          <span className="border-2 border-b-gray-400 border-r-gray-400 leading-3 pl-[0.15rem] pr-[0.2rem] text-black leading-none my-0.5 mx-0.5 mr-1"><FontAwesomeIcon icon={faTimes as any} /></span>
        </div>
        <Box className="flex w-5/6 md:w-3/12 3xl:w-1/4 bg-gray-300 p-2.5 border-2 border-b-gray-400 border-r-gray-400">
          <img src={ALBUM_ART} className="border-2 border-t-gray-400 border-l-gray-400" />
        </Box>
        <Box className="flex mt-5 md:mt-8 text-black w-5/6 md:w-full justify-center text-center uppercase text-sm md:text-md 3xl:text-xl">
          Stream BDK Now to receive an affirmation from KenTheMan
        </Box>
        {/* <Box className="flex pt-1">
          <img
            src={TIDAL}
            className="w-10 md:w-14 3xl:w-[4rem] cursor-pointer mx-1"
            onClick={() => {
              window.open('http://tidal.com/album/366546133');
              modalOpen();
            }}
          />
          <img
            src={SPOTIFY}
            className="w-10 md:w-14 3xl:w-[4rem] cursor-pointer mx-1"
            onClick={() => {
              window.open('https://kentheman.lnk.to/BDKSpotify');
              modalOpen();
            }}
          />
          <img
            src={APPLE}
            className="w-10 md:w-14 3xl:w-[4rem] cursor-pointer mx-1"
            onClick={() => {
              window.open('https://kentheman.lnk.to/BDKAppleMusic');
              modalOpen();
            }}
          />
          <img
            src={YOUTUBE}
            className="w-10 md:w-14 3xl:w-[4rem] cursor-pointer mx-1"
            onClick={() => {
              window.open('https://kentheman.lnk.to/BDKYTM');
              modalOpen();
            }}
          />
          <img
            src={AMAZON}
            className="w-10 md:w-14 3xl:w-[4rem] cursor-pointer mx-1"
            onClick={() => {
              window.open('https://kentheman.lnk.to/BDKAZM');
              modalOpen();
            }}
          />
          <img
            src={DEEZER}
            className="w-10 md:w-14 3xl:w-[4rem] cursor-pointer mx-1"
            onClick={() => {
              window.open('https://kentheman.lnk.to/Deezer');
              modalOpen();
            }}
          />
        </Box> */}
        <Box className="mt-5">
          <button className="uppercase focus:outline-none rounded-none mx-8 mb-4 px-5 pt-1.5 pb-1 bg-gray-300 text-black border-solid border-2 border-t-gray-200 border-l-gray-200 border-b-gray-400 border-r-gray-400" onClick={handleClick}>stream now</button>
        </Box>
      </Box>
      <dialog id="modal" className="dialog touch-none overflow-x-hidden -z-30 flex flex-col justify-between w-[320px] md:w-[380px] 3xl:w-[400px] h-[320px] md:h-[380px] 3xl:h-[400px] rounded-none border-solid border-4 border-t-gray-200 border-l-gray-200 border-b-gray-400 border-r-gray-400">
        <img src={BG_IMG} className="h-full w-full object-cover object-center absolute top-0 left-0 -z-30" />
        <div className="m-8 text-lg md:text-2xl font-nokia">{currentMessage}</div>
        <button className="uppercase focus:outline-none rounded-none mx-8 mb-4 px-5 pt-1.5 pb-1 bg-gray-300 text-black border-solid border-2 border-t-gray-200 border-l-gray-200 border-b-gray-400 border-r-gray-400" onClick={modalClose}>OK</button>
      </dialog>
    </Box>
  );
};

export default Showcase;
