import { useEffect, useState } from "react";

const Preloader = () => {
  const [loaded, setIsLoaded] = useState(false);

  useEffect(() => {
    window.onload = () => {
      // scroll top to 0

      setIsLoaded(document.readyState === "complete");

      setTimeout(() => {
        window.scrollTo({ top: 0 });
      }, 300);
    };
  }, []);

  return (
    <div
      className="fixed top-0 left-0 w-full h-screen bg-black"
      style={{ zIndex: !loaded ? 123123123 : -123123123 }}
    />
  );
};

export default Preloader;
