import { useSelector } from "react-redux";
import { Box } from "../../components/StyledComponent";
import Showcase from "./Showcase/Showcase2";

export default function Home() {
  return (
    <Box className="text-white h-screen w-full flex flex-col justify-center items-center font-monomials">
      <Showcase />
    </Box>
  );
}
