import { HTMLAttributes } from 'react';

type ContainerProps = HTMLAttributes<HTMLDivElement> & {
  motion?: boolean;
};

const Container = ({ ...props }: ContainerProps) => {
  return (
    <div className="w-5/6 mx-auto items-center flex py-16 flex-col relative md:w-4/5" {...props}>
      {props.children}
    </div>
  );
};

export default Container;
